import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import linkicon from "../../../Assets/icons/link.png";
const ProjectsCard = ({ data, projectId }) => {
  const { _id } = data;

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 * projectId }}
      className=" my-4 mx-auto "
    >
      <Link to={`/projects/details/${_id}`} target="_blank">
        <div className="  border-2 border-border-bg rounded min-w-[200px] max-w-[350px] ">
          <img
            src={data?.gellaryImages[0]}
            className="rounded h-[180px] w-full"
            alt="Project  "
          />
          {/* Content */}
          <div className="px-2 text-left  ">
            <h2 className="text-xl font-bold my-4 text-text-yellow leading-5 line-clamp-1 overflow-hidden text-ellipsis">
              {data?.projectTitle}
            </h2>
            <p class="leading-5 line-clamp-2 overflow-hidden text-ellipsis">
              {data?.sortdes
                ? data.sortdes
                : "There Will be project description"}
            </p>
            <div className="my-4 md:my-6 flex justify-end">
              <a
                href={data?.liveSite}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-button px-3.5 py-2.5 rounded-lg text-sm flex items-center space-x-2"
              >
                <span>Preview</span>
                <img src={linkicon} alt="  Link Icon" />
              </a>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default ProjectsCard;
